
/*
|--------------------------------------------------------------------------
| Custom Javascript code
|--------------------------------------------------------------------------
|
| Now that you configured your website, you can write additional Javascript
| code inside the following function. You might want to add more plugins and
| initialize them in this file.
|
*/

$(function() {

	$(document).ready(function(){

 		var
		found=0,
		getCurentFileName=function(){
			var pagePathName= window.location.pathname;
			return pagePathName.substring(pagePathName.lastIndexOf("/") + 1).toLowerCase();
		},
		getDomainName=function(){
			return window.location.hostname;
		},

		setRecaptcha = function (fid) {
            var fd = $.Deferred();
            var source = $('#' + fid).data('source');
            grecaptcha.ready(function () {
                grecaptcha.execute('6LcH5N4UAAAAALKpcvhyW6nYioPITytc3ES9zx3o', {
                    action: source
                }).then(function (token) {
                    $('#' + fid).find('.recaptcha').val(token);
                    fd.resolve(true);
                });
            });
            return fd.promise();
        },
        
		resetForm = function (fid) {
            if (fid == 'form-contact') {
                $("#name,#designation,#company, #phone, #email, ,#message, .recaptcha").val('');
            } 
            else if (fid == 'form-demo') {
                $("#name,#designation,#company, #phone, #email, #website, .recaptcha").val('');
            } 
            else if (fid == 'call-action') {
                $("#name, #phone, .recaptcha").val('');
            } 
            else if (fid == 'form-blog') {
                $("#name, #email,#comment, .recaptcha").val('');
            } 
            else if (fid == 'form-exitshow') {
                $("#name, #email, #phone, .recaptcha").val('');
            } 
			$('[data-toggle="tooltip"], .tooltip').tooltip("hide");
        },	
		
		recordConversionAction = function(response){
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			gtag('config', 'AW-1032018138', { 'send_page_view': false });
			var submission = 'failed';
			if(response.type=="alert-success"){
				var submission = 'success';
			} 
			switch(response.source){
				case 'contact' :
					gtag('event', 'conversion', {
						'send_to': 'AW-1032018138/1igyCMvzuowDENqxjewD',
						'value': 500.0,
						'currency': 'INR',
						'source': 'contactus',
						'submission': submission
					});
				break;
				case 'inquiry':
					// Submit Lead Form
					gtag('event', 'conversion', {
						'send_to': 'AW-1032018138/1igyCMvzuowDENqxjewD',
						'value': 1000.0,
						'currency': 'INR',
						'source': 'inquiry',
						'submission': submission
					});
				break;
				case 'exitshow':
					// Exit Show Callback Request
					gtag('event', 'conversion', {
						'send_to': 'AW-1032018138/1igyCMvzuowDENqxjewD',
						'value': 500.0,
						'currency': 'INR',
						'source': 'exitshow',
						'submission': submission
					});
				break;
				default:
				break;
			}

		};
		
		

		if ( typeof $.fn.validate!== 'undefined' && $.isFunction($.fn.validate) ){
			

			$.validator.setDefaults({
				onkeyup: function(elements) { 
				},
				errorElement: 'span',
				errorPlacement: function(error, element) {
					$(element).parents('.form-group').addClass('has-error');
					if ($(element).attr('type') == 'hidden') {
						$(':submit').attr({
							html : true,
							"data-toggle": "tooltip",
							"data-original-title": $(error).text(),
							"data-placement": "left"
						}).tooltip('show');
					} else {
						$(element).attr({
							"data-toggle": "tooltip",
							"data-original-title": $(error).text(),
							"data-placement": $(element).data('placement') || "left"
						}).tooltip('show');
					}
				},
				success: function(error, element) {
					if ($(element).attr('type') == 'hidden') {
						$(':submit').attr('data-original-title','').parents('.form-group').removeClass('has-error');
					} else {
						$(element).attr('data-original-title','').parents('.form-group').removeClass('has-error');
					}
				},
				submitHandler: function(form,event) {
					event.preventDefault();
					var $trigger = $(form);
					$trigger.find(':submit').addClass('disabled').attr('disabled', true).find('i').replaceWith('<i class="fa fa-spinner fa-spin"></i>');
					$.when(setRecaptcha($trigger.attr('id'))).done(function (rc) {
						form.submit();
					});
				}
			});
	
	
			$('#form-demo').validate({
				rules:{
					name:{
						required: true, 
						letterswithbasicpunc: true,
						minlength: 2,
						maxlength: 200
					},
					designation: { 
						required: true, 
						minlength: 6,
						maxlength: 50
					},
					company: { 
						required: true, 
						minlength: 6,
						maxlength: 50
		  			},
					phone: { 
						required:true,
						intTelphone:true,
						intTelType:[1,2]			
					},
					email: { 
						required: true,
						email:true,
					},
					website: { 
						required: false, 
						website:true
					},
					appointment: { 
						required: false, 
						dateTimeLDT:true
					}
				},
				messages:{
					name:{
						required: ' Enter Your Name ',
					},
					designation: { 
						required: ' Enter Your Designation ', 
					},
					company: { 
						required: ' Enter Company Name ', 
					},
					phone: { 
		  				required: ' Enter Your Mobile Number',  
					},
					email: { 
						required: ' Enter Email ID ', 
						email: ' Please Enter Valid Business Email ID '
					} 
					  
				},  
				
				onfocusin: function(elements){
					if($(elements).hasClass('intltel')){
						var value = $(elements).intlTelInput("getNumber", intlTelInputUtils.numberFormat.INTERNATIONAL);
						if(value==''){
							var countryData = $(elements).intlTelInput("getSelectedCountryData"); 
							var countryCode = "+" + countryData.dialCode;
							$(elements).val(countryCode);
						}
					}
				},
				onfocusout: function(elements){
					var id = $(elements).attr("id");
					if(id=='email'){

						var value = $(elements).val();
						value=value.replace(/\s+/g, '');
						$(elements).val($.trim(value.toLowerCase()));
					}
					if($(elements).hasClass('intltel')){
						value = $(elements).intlTelInput("getNumber", intlTelInputUtils.numberFormat.INTERNATIONAL);
						$(elements).val($.trim(value));
					}
					$(elements).valid(); 
				}
			});	
			
			$('#form-contact').validate({
				rules:{
					name:{
						required: true, 
						letterswithbasicpunc: true,
						minlength: 2,
						maxlength: 200
					},
					designation: { 
						required: true, 
						minlength: 6,
						maxlength: 50
					},
					company: { 
						required: true, 
						minlength: 6,
						maxlength: 50
		  			},
					phone: { 
						required:true,
						intTelphone:true,
						intTelType:[1,2]			
					},
					email: { 
						required: true,
						email:true,
					},
					message: { 
						required: true, 
					}
				},
				messages:{
					name:{
							required: ' Enter Name',
					},
					designation: { 
							required: ' Enter Your Designation', 
					
					},
					company: { 
							required: ' Enter Company Name', 
					
					},
					phone: { 
			  				required: ' Enter Mobile Number' 					
					},
					email: { 
							required: ' Enter email', 
							email: 'please Enter Valid Business Email ID'
					},
					message: { 
	  		  				required: ' Enter Your Message', 
					},
					  
				},  
				
				onfocusin: function(elements){
					if($(elements).hasClass('intltel')){
						var value = $(elements).intlTelInput("getNumber", intlTelInputUtils.numberFormat.INTERNATIONAL);
						if(value==''){
							var countryData = $(elements).intlTelInput("getSelectedCountryData"); 
							var countryCode = "+" + countryData.dialCode;
							$(elements).val(countryCode);
						}
					}
				},
				onfocusout: function(elements){
					var id = $(elements).attr("id");
					if(id=='email'){

						var value = $(elements).val();
						value=value.replace(/\s+/g, '');
						$(elements).val($.trim(value.toLowerCase()));
					}
					if($(elements).hasClass('intltel')){
						value = $(elements).intlTelInput("getNumber", intlTelInputUtils.numberFormat.INTERNATIONAL);
						$(elements).val($.trim(value));
					}
					$(elements).valid(); 
				}
			});	

			$('#form-blog').validate({  
				rules:{
					name:{
						required: true, 
						letterswithbasicpunc: true,
						minlength: 2,
						maxlength: 200
					},
					email: { 
						required: true,
						email:true,
					},
					comment: { 
						required: true, 
					}
				},
				messages:{
					name:{
							required: ' Enter Name',
					},
					email: { 
							required: ' Enter email', 
							email: 'please Enter Valid Email ID'
					},
					comment: { 
	  		  				required: ' Enter Your Message', 
					},
					  
				},  
				
				onfocusout: function(elements){
						var id = $(elements).attr("id");
						if(id=='email'){

							var value = $(elements).val();
							value=value.replace(/\s+/g, '');
							$(elements).val($.trim(value.toLowerCase()));
						}
						$(elements).valid(); 
				},
				errorElement: 'span',
				errorPlacement: function(error, element) {
					$(element).parents('.form-group').addClass('has-error');
					if ($(element).attr('type') == 'hidden') {
						$(':submit').attr({
							html : true,
							"data-toggle": "tooltip",
							"data-original-title": $(error).text(),
							"data-placement": "bottom"
						}).tooltip('show');
					} else {
						$(element).attr({
							"data-toggle": "tooltip",
							"data-original-title": $(error).text(),
							"data-placement": $(element).data('placement') || "bottom"
						}).tooltip('show');
					}
				},
			});	
			
			$('#form-inquiry').validate({  
				rules:{ 
					//ignore:""
					name:{
						required: true, 
						letterswithbasicpunc: true,
						minlength: 2,
						maxlength: 250
					},
					company: { 
						required: true, 
						minlength: 2,
						maxlength: 250
		  			},
					phone: { 
						required:true,
						intTelphone:true,
						intTelType:[1,2]			
					},
					email: { 
						required: true,
						email:true,
					},

				},
				messages:{
					name:{
						required: ' Enter Name',
					},
					company: { 
							required: ' Enter Company Name',  
					},
					phone: { 
						required: 'Enter Your Mobile Number', 
					},
					email: { 
							required: ' Enter Business Email ID', 
							email: 'Please Enter Valid Business Email ID'
					},
					  
				},  
				onfocusin: function(elements){
					if($(elements).hasClass('intltel')){
						var value = $(elements).intlTelInput("getNumber", intlTelInputUtils.numberFormat.INTERNATIONAL);
						if(value==''){
							var countryData = $(elements).intlTelInput("getSelectedCountryData"); 
							var countryCode = "+" + countryData.dialCode;
							$(elements).val(countryCode);
						}
					}
				},
				
				onfocusout: function(elements){
					if($(elements).hasClass('intltel')){
						var value = $(elements).intlTelInput("getNumber", intlTelInputUtils.numberFormat.INTERNATIONAL);
						$(elements).val($.trim(value));
					}
					$(elements).valid(); 
				},
				errorElement: 'span',
				errorPlacement: function(error, element) {
					$(element).parents('.form-group').addClass('has-error');
					if ($(element).attr('type') == 'hidden') {
						$(':submit').attr({
							html : true,
							"data-toggle": "tooltip",
							"data-original-title": $(error).text(),
							"data-placement": "bottom"
						}).tooltip('show');
					} else {
						$(element).attr({
							"data-toggle": "tooltip",
							"data-original-title": $(error).text(),
							"data-placement": $(element).data('placement') || "bottom"
						}).tooltip('show');
					}
				},
			});	

		}

		if ( typeof $.fn.intlTelInput!== 'undefined' && $.isFunction($.fn.intlTelInput) ){
			$(".intltel").intlTelInput({
			  allowDropdown: false,
			  dropdownContainer: "body",
			  initialCountry: "auto",
			  defaultCountry:'in',
			  nationalMode: false,
			  numberType: "MOBILE",
			  preventInvalidNumbers: true,
			  utilsScript: "../../assets/plugin/intl-tel-input/js/utils.js",
			  geoIpLookup: function(callback) {
				$.get("https://api.ipapi.com/api/check?access_key=0a56fe04cbf84d22aeea445907ff02d0", function() {}, "jsonp").always(function(resp) {
				  var countryCode = (resp && resp.country_code) ? resp.country_code : "IN";
				  callback(countryCode);
				});			  
				callback("IN");
			  },
			});
		}

		/*Navbar Set active */
		var currPage= getCurentFileName(),
		activemenu=$('.breadcrumb').data('active-menu');
		//console.log(currPage);
		//console.log(activemenu);
		if(currPage==''){
			$('.navbar-mobile .nav-navbar>li:eq(0)').addClass("active");
		}else{
			$('.navbar-mobile .nav-navbar>li>a').each(function () {
				var currLink = $(this);
				var refElement = currLink.attr("href");
				if (refElement.indexOf(currPage) >= 0 || refElement.indexOf(activemenu) >= 0) {
					$('.navbar-mobile .nav-navbar>li>li').removeClass("active");
					currLink.parent('li').addClass("active"); 
				}
			});
		}	
		/*Navbar Set active ends*/

		/*UTM Parameters*/
		var queryForm = function(settings){
			var reset = settings && settings.reset ? settings.reset : false;
			var self = window.location.toString();
			//console.log(self);
			var querystring = self.split("?");
			if (querystring.length > 1) {
			  var pairs = querystring[1].split("&");
			  //console.log(pairs);
			  for (i in pairs) {
				var keyval = pairs[i].split("=");
				//console.log(keyval);
				if (reset || localStorage.getItem(keyval[0]) === null) {
				  localStorage.setItem(keyval[0], decodeURIComponent(keyval[1]));
				}
			  }
			}

			var hiddenFields = document.querySelectorAll("input[type=hidden], input[type=text]");
			for (var i=0; i<hiddenFields.length; i++) {
			  var param = localStorage.getItem(hiddenFields[i].name);
			  if (param) {
				  document.getElementsByName(hiddenFields[i].name).forEach(function(field){field.value = param;});
			  }
			}
		}
		/*UTM Parameters End*/ 

		if(window.response){
			recordConversionAction(window.response);
		}

		$(document).on('click', '#wabtn', function (e) { 
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			gtag('config', 'AW-1032018138', { 'send_page_view': false });
			gtag('event', 'conversion', {
				'send_to': 'AW-1032018138/1igyCMvzuowDENqxjewD',
				'value': 100.0,
				'currency': 'INR',
				'source': 'whatsappbutton',
				'submission': ''
			}); 
		});

		$(document).on('click','#inquirypopup',function (e) { 
			e.preventDefault();
			var target = $('#inquiry-popup'); 
			
			if($(target).hasClass('show')){
				$('[data-toggle="tooltip"], .tooltip').tooltip("hide");
				$(target).removeClass('show');
				e.stopPropagation();
			}else{
				$('[data-toggle="tooltip"], .tooltip').tooltip("hide");
				$(target).addClass('show');
				setTimeout(function() { $(target).find('input:text:visible:first').focus(); }, 300);
				e.stopPropagation();
			}
			
		});

		$(document).on('click', '.popup [data-dismiss]', function() {
			$('[data-toggle="tooltip"], .tooltip').tooltip("hide");
		});
		
		// Remove popup and tooltips on escape button press
		document.addEventListener('keydown', function(event){ 
			if(event.key === "Escape"){
				$('.popup').removeClass('show');
				$('[data-toggle="tooltip"], .tooltip').tooltip("hide");
				event.stopPropagation();
			}
		});


		//Lazy-Image
		$("img.lazy").lazy();
			
		//datetimepicker
		$('input.datetimesbs').flatpickr({
			enableTime: true,
			dateFormat: "d M Y h:i K", 
			minDate: "today",
			maxDate: new Date().fp_incr(30)  ,
			minuteIncrement:30,
			minTime: "10:00",
			maxTime: "19:00",
			allowInput: true,
			"disable": [
				function(date) {
					return (date.getDay() === 0);

				}
			]
		});
       
		// <!-- Global site tag (gtag.js) - Google Ads: 1032018138 -->
		window.dataLayer = window.dataLayer || [];
		function gtag(){dataLayer.push(arguments);}
		gtag('js', new Date());

		gtag('config', 'AW-1032018138');

	});  // Document ready closed.


});

